// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  baseHref: '/reporting/',
  clientId: '958b91dd-f2f9-4ad7-b2a1-19f333e80e10',
  backendApiScope: 'api://6859dd49-654f-4140-ae07-f5d2defa8ff0/access_as_user',
  apiResourceUri: 'https://reporting-api-uk.ukg2go.agpre.net/api/',
  dateFormatUK: 'dd/MM/yyyy',
  dateTimeFormatUK: 'dd/MM/yyyy HH:mm:ss',
  groups: {
    DEFAULT: '60cf95db-5b6d-49ce-a1fc-4faef13f2c2b'
  },
  ukUrl: 'https://reporting-api-uk.ukg2go.agpre.net/api/',
  ukLogoPath: 'assets/img/ukg-logo-min.png',
  ausUrl: 'https://reporting-api-aus.js2go.johnsands.com/api/',
  ausLogoPath: 'assets/img/js-logo-min.png'
};
